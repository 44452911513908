import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import {
  HighlightedText,
  Faqs,
  featureOverviewFeatures,
} from "@userbrain/website-ui";

import Layout from "../../layouts/layout";
import { FeatureOverview } from "../../components/FeatureOverview";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderOverlapDoubleImage } from "../../components/HeaderOverlapDoubleImage";
import { Slider } from "../../components/Slider";
import { ThreeColumnsDefault } from "../../components/ThreeColumnsDefault";
import { ThreeColumnsIcons } from "../../components/ThreeColumnsIcons";

import * as helperStyles from "../helpers.module.css";

import imageHeader1 from "../../images/inviteYourOwnHeader1.jpg";
import imageHeader2 from "../../images/inviteYourOwnHeader2.jpg";

import imageIconInviteYourOwnTesters from "@userbrain/website-ui/images/feature-icon-iyop.svg";
import imageTargetSpecificUsers from "../../images/portrait-2.svg";
import imageChooseAnyLanguage from "../../images/lineIcons/choose-any-language.svg";
import imageSimplyDropALink from "../../images/lineIcons/apply-and-take-qualification-test.svg";
import imageExamplesAndTemplates from "../../images/examples.jpg";
import imageTaskTypes from "../../images/taskTypes.jpg";
import imageSitBackAndRelax from "../../images/sitBackAndRelax.png";

import imageDashboardSetUpUserTest from "../../images/dashboardSetUpUserTest.jpg";
import imageDashboardInviteLink from "../../images/dashboardInviteLink.jpg";
import imageDashboardIyopTesterSignup from "../../images/dashboardIyopTesterSignup.jpg";
import imageDashboardNewSession from "../../images/dashboardNewSessions.jpg";
// import imageBlackThoughtBalloon from '../../images/analyzeBlackThoughtBalloon.jpg';

export default function InviteYourOwnTesters() {
  return (
    <Layout
      activeMenuItem={"features/invite-your-own-testers"}
      title={"Invite your own testers"}
      metaDescription={
        "Want to user test with your own customers, friends, or target audience? Right this way."
      }
    >
      <article>
        <HeaderOverlapDoubleImage
          featureIcon={imageIconInviteYourOwnTesters}
          featureIconAlt={"Open envelope"}
          overline={"Invite your own testers"}
          title={
            <>
              Test with your own{" "}
              <span className={helperStyles.h1Serif}>
                customers, friends, or target audience.
              </span>
            </>
          }
          c2aButtonLabel={"Sign up now"}
          titleWidth={"16em"}
          buttonSubtext={"No credit card required"}
          image1Src={imageHeader1}
          image2Src={imageHeader2}
          image1Alt={"Smiling woman wit red lips"}
          image2Alt={"Guy sitting behind a laptop computer"}
        />

        <div className={helperStyles.container}>
          <ThreeColumnsIcons
            className={helperStyles.spacingLg}
            title={"Flexibility to test with whoever you want."}
            titleWidth={"12em"}
            col1Image={imageTargetSpecificUsers}
            col1Alt={"Line drawing of a test user"}
            col1Heading={"Target specific users"}
            col1Text={
              "Target people using your site or on social platforms with ads to get the exact demographics you’re looking for."
            }
            col2Image={imageChooseAnyLanguage}
            col2Alt={"Line drawing of a globe with a pin"}
            col2Heading={"Choose any language"}
            col2Text={
              "Test with people from all around the world and give them tasks in any language of your choice."
            }
            col3Image={imageSimplyDropALink}
            col3Alt={"Line drawing of a hand with a computer mouse"}
            col3Heading={"Simply drop a link"}
            col3Text={
              "Share your unique Userbrain link with as many people as you like by simply dropping them a link via email, text, or Slack."
            }
          />
        </div>

        <Slider
          className={helperStyles.spacingLg}
          title={"Invite your own testers"}
          subtitle={"How it works:"}
          slides={[
            {
              title: "Set up a user test.",
              imageSrc: imageDashboardSetUpUserTest,
              imageAlt:
                "Testing with your own users first step: Set test name, url and language",
            },
            {
              title: <>Share invite link.</>,
              imageSrc: imageDashboardInviteLink,
              imageAlt:
                "Testing with your own users second step: Enable and share an invitation link",
            },
            {
              title: <>Invitees need to download Userbrain’s recording tool.</>,
              imageSrc: imageDashboardIyopTesterSignup,
              imageAlt:
                "Testing with your own users third step: Userbrain’s recording tool",
            },
            {
              title: (
                <>
                  Once they’ve recorded, videos will show up on your dashboard.
                </>
              ),
              imageSrc: imageDashboardNewSession,
              imageAlt:
                "Testing with your own users fourth step: New sessions show up in the dashboard",
            },
          ]}
        />

        <div className={helperStyles.container}>
          <ThreeColumnsDefault
            className={helperStyles.spacingLg}
            title={"Other perks & goodies:"}
            col1Image={imageExamplesAndTemplates}
            col1Alt={"Tree boxes with a fragment of a user testing task"}
            col1Heading={"Examples & templates"}
            col1Text={
              "To make things a little easier, we’ve put together a library of examples and templates from different industries and scenarios to inspire your next user test."
            }
            col1LinkTo={"/user-testing-templates"}
            col1LinkText={"View templates"}
            /*
          col1Image={imageBlackThoughtBalloon}
          col1Alt={'Black thought balloon'}
          col1Heading={'Hear what other people think'}
          col1Text={'We get your testers to speak aloud so you can hear exactly what thought process is going on while they carry out the tasks.'}
*/

            col2Image={imageTaskTypes}
            col2Alt={
              "Select box with options: Task, Rating scale, Multiple choice, Written response"
            }
            col2Heading={"Different task types"}
            col2Text={
              "You can get your users to test in many different ways. From rating scales to multiple or single choice – you decide what type of task your users will get."
            }
            col3Image={imageSitBackAndRelax}
            col3Alt={"Hand showing OK gesture"}
            col3Heading={"Sit back and relax"}
            col3Text={
              "Once you’ve set up your test and distributed the links, you can sit back and relax. No scheduling, no moderation — your testers can test in their own time."
            }
          />

          <Faqs
            className={helperStyles.spacingLg}
            questions={[
              {
                question: "Who should I test with?",
                answer: (
                  <>
                    When inviting your own testers, you should watch out for
                    bias. Sure you can test with your mom, but she is obviously
                    going to love every single thing you produce. So actually
                    the classic mom test isn’t as good as it’s cut out to be.
                    Same goes for your best friend.
                    <br />
                    <br />
                    Be sure to invite testers that are:
                    <ul>
                      <li>Unbiased</li>
                      <li>New to the product or feature you are testing</li>
                      {/*Todo: WTF?*/}
                      <li>Fall into a specific target group.</li>
                    </ul>
                  </>
                ),
              },
              {
                question: "What about compensation?",
                answer:
                  "As opposed to tapping into our pool of user testers, whether and how much you’ll need to compensate testers — is completely up to you. Unfortunately, you won’t be able to compensate through Userbrain but we recommend using services like Paypal.",
              },
              {
                question: "What do I need to get started? ",
                answer: (
                  <ul>
                    <li>A Userbrain subscription plan</li>
                    <li>A bunch of willing user testers</li>
                    <li>
                      Test scenarios to test (we’ve got great templates &
                      examples to get you started)
                    </li>
                  </ul>
                ),
              },
              {
                question:
                  "Userbrain tester pool vs. testing with your own testers, what’s the difference?",
                answer: (
                  <>
                    <p>
                      By testing with Userbrain testers you can save time, money
                      and still get the results you need. On the other hand,
                      testing with your own testers gives you the option to test
                      with a very specific target audience and/or language. Both
                      have their benefits and ultimately it’s up to you.
                    </p>
                    <p>
                      If you choose to test with our pool of testers, it means
                      you can set up your test, relax and get results in just a
                      few hours. You can also choose basic demographics. When
                      testing with your own testers you can choose very specific
                      demographics but have to recruit, pay, and manage them
                      yourself.
                    </p>
                    <p>
                      Generally speaking, we’ve discovered that no matter what
                      the demographics of a tester are, they find out what’s
                      working for your product — and what’s not.
                    </p>
                  </>
                ),
              },
              {
                question: "Is there a time limit on tests with my own testers?",
                answer:
                  "Nope. Your own user testers can test as long as they want.",
              },
            ]}
          />

          <GetStartedBox
            className={helperStyles.spacingLg}
            title={
              <>
                Want to test with your own <br />
                <HighlightedText cursor>your own testers</HighlightedText>?
              </>
            }
            c2aButtonLabel={"Get started"}
          />
        </div>
        <div
          className={cn(
            helperStyles.container,
            helperStyles.container_featureOverview
          )}
        >
          <FeatureOverview
            className={helperStyles.spacingLg}
            title={
              "Userbrain features for easy, unmoderated remote user testing"
            }
            titleWidth={"16em"}
            features={featureOverviewFeatures}
          />
        </div>
      </article>
    </Layout>
  );
}
